<template>
    <div>
        <el-tabs>
            <el-tab-pane label="证书">
                <el-button type="primary" @click="exportPDF('showTotal')" size="medium" style="margin:5px;">导出PDF</el-button>
                

                <div id="showTotal">
                    <div v-for="(item,index) in event_awards" :key="index" class="certContainer5">
                        <img
                        src="../../assets/cer_am.jpg"
                        class="model"
                        />
                     
                        <div class="event" v-if="item.award_type == 'personal total'">{{ `${showEvent(item.application_event)}`  }}</div>


                        <div class="award" v-if="item.award_type == 'personal total'"> {{ item.award_show_lable + ' and ' + item.award_name }}</div>

                        <div class="student" v-if="item.award_type == 'personal total'">
                            <span class="name">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
                        </div>
                        <div class="school" v-if="item.award_type == 'personal total'">{{ item.account.account_nameEN }}</div>


                        
                        <!-- <div class="event" v-if="item.award_type == 'team total'">{{ item.application_event }}</div> -->

                        <div class="award_team_out" v-if="item.award_type == 'team total'"> {{ showEvent(item.application_event) }}</div>

                        <div class="student_team_out" v-if="item.award_type == 'team total'">
                            <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
                        </div>
                        <div class="school_team_out" v-if="item.award_type == 'team total'">{{ item.account.account_nameEN }}</div>


                        <div class="school_out_team" v-if="item.award_type == 'team total'" >Outstanding School Team</div>


                        <div class="award_team" v-if="item.award_type == 'event expression'"> {{ item.manually_add_award_lable}}</div>

                        <div class="student_team" v-if="item.award_type == 'event expression'">
                            <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
                        </div>
                        <div class="school_team" v-if="item.award_type == 'event expression'">{{ item.account.account_nameEN }}</div>



                        <div class="award_rank" v-if="item.award_type == 'event complete'"> {{`${showEvent(item.application_event)}`   }}</div>

                        <div class="student_rank" v-if="item.award_type == 'event complete'">
                            <span class="name_team">{{`${upfirstName(item.student.student_givenName_pinyin)} ${upfirstName(item.student.student_lastName_pinyin)}`}}</span>
                        </div>
                        <div class="school_rank" v-if="item.award_type == 'event complete'">{{ item.account.account_nameEN }}</div>


                        <div class="info_rank" v-if="item.award_type == 'event complete'">Has successfully completed 12-hour intense college-level studies and examinations with strong academic rigor <br> in the subject of</div>
                        <!-- <div class="event" v-if="item.award_type == 'event rank'">{{ item.application_event }}</div> -->






                    </div>
                </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="证书2">
                <el-button type="primary" @click="exportPDF('showTotal')" size="medium">导出证书</el-button>
                <div id="showTotalScreen">
                    <div v-for="(item,index) in total_awards" :key="index" class="certContainer1">
                        <img src="../../../assets/FBLA/tv_10.jpg" class="model"/>
                        <div class="event">Banking & Financial Systems</div>
                        <div class="award">National First Prize</div>
                        <div class="student">
                            <span class="name">Jielun Zhou</span>
                            <span class="name">Jielun Zhou</span>
                            <span class="name">Jielun Zhou</span>
                        </div>
                        <div class="school">Jiangsu Nanjing NO.179 Example School Guangxi Nanning Science</div>
                    </div>
                </div>
            </el-tab-pane> -->
            <!-- <el-tab-pane label="项目奖">
                <el-button type="primary" @click="exportPDF('showEvent')" size="medium">导出证书</el-button>
                <div id="showEvent">
                    <div v-for="item in event_awards" :key="item._id.$id" class="container">
                        <img src="../../../assets/award.jpg" class="model"/>
                        <div class="student">{{upfirstName(item.student.student_givenName_pinyin)+' '+upfirstName(item.student.student_lastName_pinyin)}}</div>
                        <div class="account">{{item.account?item.account.account_nameEN:''}}</div>
                        <div>
                            <div class="event">{{item.application_event}} - {{award_type_label_arr[item.award_type]}}</div>
                            
                            <div class="award">National {{item.real_rank}}{{rank(item.real_rank)}} Place</div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="参赛证明">
                <el-button type="primary" @click="exportPDF('showComplete')" size="medium">导出证书</el-button>
                <div id="showComplete">
                    <div v-for="item in completes" :key="item._id.$id" class="container">
                        <img src="../../../assets/complete.jpg" class="model"/>
                        <div class="student">{{upfirstName(item.student.student_givenName_pinyin)+' '+upfirstName(item.student.student_lastName_pinyin)}}</div>
                        <div class="account">
                            <div>{{item.account?item.account.account_nameEN:''}}</div>
                            <div style="margin-top:30px;">has completed all competitive events in Economics, Financial Literacy and Business Case</div>
                        </div>
                    </div>
                </div>
            </el-tab-pane> -->
            <!-- <el-tab-pane label="成绩单">
                <el-button type="primary" @click="exportPDF('showTranscript')" size="medium" style="margin:5px;">导出PDF</el-button>

                <div id="showTranscript">
                    <div v-for="(item,index) in transcripts" :key="index" class="transcriptContainer" style="margin-bottom:10px;">
                        <img src="../../assets/参赛证明(2).jpg" class="model_p"/>
                        <div class="name">{{upfirstName(item.student_info.student_givenName_pinyin) + ' ' + upfirstName(item.student_info.student_lastName_pinyin) + ' ' + item.student_info.student_lastName + ' ' + item.student_info.student_givenName}}</div>
                        <div class="info">
                            <div>
                                <span class="tag">邮箱: </span>
                                <span class="content">{{item.student_info.student_email}}</span> | 
                                <span class="tag">性别: </span>
                                <span class="content">{{item.student_info.student_gender == '男'? '男' : '女'}}</span> | 
                                <span class="tag">预计毕业年份: </span>
                                <span class="content">{{item.student_info.student_graduation}}</span>
                            </div>
                            <div style="margin-top:6px">
                                <span class="tag">学校: </span>
                                <span class="content">{{item.account_info.account_name}}</span>
                            </div>
                        </div>
                        <div class="score" >
                            <div  v-for="score in item.score_info" :key="score._id.$id">
                                <div v-if="score.score_type == 'team'" style="margin-bottom:20px;" >
                                    <div class="title">{{score.application_event + ' - ' + score.mode }}</div>
                                    <div style="margin-top:5px">Score:<span>{{score.total_score}}</span>/{{score.full_score}}  <span style="margin-left:10px;"></span>Percentile: Top <span>{{score.percentile}}</span></div>
                                    
                                    <div style="margin-top:5px"># of Competitors: {{score.competitor_count}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="score1" >
                            <div  v-for="score in item.score_info" :key="score._id.$id">
                                <div  v-if="score.score_type == 'personal'" style="margin-bottom:20px;">
                                    <div class="title">{{score.application_event + ' - ' + '客观笔试'}}</div>
                                    <div style="margin-top:5px">分数:<span>{{score.total_score}}</span>/{{score.full_score}} ></div>  
                                    <div style="margin-top:5px"># of Competitors：{{score.competitor_count}}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </el-tab-pane> -->
        </el-tabs>
    </div>
</template>

<script>
import { getStudentAwards,getStudentTranscripts} from '../../api/index.js'
import {getUserId,getProjectCode} from '../../utils/store'
import {formatDate} from '../../utils/date'

export default {
  name: 'awardfbla',
  data () {
    return {
        project_code:getProjectCode(),
        total_awards: [],
        event_awards: [],
        completes: [],
        transcripts:[],
        type:'all',
        id:'',
        award_create_time:'2022年6月7日',
        award_type_label_arr:{
            'personal total':'Individual Total Score Rank',
            'event rank':'Individual Event Rank',
            'event team rank':'Team Event Rank'
        },
        direction:1

    }
  },
  mounted () {

    this.type = this.$route.query.type
    this.id = this.$route.query.id

    this.fetchData()
  },
  methods: {
    fetchData () {

        console.log(1111,getProjectCode())
        
        getStudentAwards(getProjectCode(),getUserId()).then(res => {
            if (res.data.code === 0) {
            this.total_awards = res.data.data.filter(
                (item) => item.award_type === 'personal total'
            )
            this.event_awards = res.data.data.filter(
                (item) => item.award_type === 'event rank' || item.award_type === 'event team rank' || item.award_type === 'team total' || item.award_type === 'personal total'  || item.award_type === 'event expression' || item.award_type === 'event complete'
            )
            }
        })
        //   getCompletes().then(res => {
        //     if (res.data.code === 0) {
        //       this.completes = res.data.data
        //     }
        //   })
        getStudentTranscripts(getProjectCode(),getUserId()).then(res => {
            console.log(res)
            if (res.data.code === 0) {
                this.transcripts = res.data.data
            };

        })
    
      
    },
    showEvent(event){

        if (event == 'Artithon'){
            return 'Artithon - Academic Marathon in Art'
        }

        if (event == 'Biothon'){
            return 'Biothon - Academic Marathon in Biology'
        }

        if (event == 'Chemithon'){
            return 'Chemithon - Academic Marathon in Chemistry'
        }
        if (event == 'Econthon'){
            return 'Econthon - Academic Marathon in Economics'
        }
        if (event == 'Geothon'){
            return 'Geothon - Academic Marathon in Geography'
        }
        if (event == 'Historithon'){
            return 'Historithon - Academic Marathon in History'
        }
        if (event == 'Mathethon'){
            return 'Mathethon - Academic Marathon in Mathematics'
        }

        if (event == 'Physithon'){
            return 'Physithon - Academic Marathon in Physics'
        }

        if (event == 'Psychothon'){
            return 'Psychothon - Academic Marathon in Psychology'
        }    


    },
    formatCreateTime(create_time) {
      var time = JSON.parse(create_time) * 1000
      var temp_date = new Date(time)
      return formatDate(temp_date, 'yyyy/MM/dd')
    },
    rank (number) {
      var tail = number.toString().charAt(number.toString().length-1,1)
      if (tail === '1' && number !== 11) {
        return "st";
      } else if (tail === '2' && number !== 12) {
        return "nd";
      } else if (tail === '3' && number !== 13) {
        return "rd";
      } else {
        return "th";
      }
    },
    upfirstName (name) {
        if (name) {
            const transferName = name.toLowerCase()
      return transferName.charAt(0).toUpperCase() + transferName.slice(1)     
        }else{
            return name
        }
      
    },
    exportPDF (id) {


        var style = document.createElement('style');

        if (id == 'showTotal'){
            // style.innerHTML = "@page{size:landscape 1528px 1080px;}";
            style.innerHTML = "@page{size:A4 landscape;}";
        }else{
            // style.innerHTML = "@page{size:portrait 2480px 3508px;margin: auto 0mm}";
            style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
            document.getElementsByTagName("body")[0].style.zoom = 1
        }

        window.document.head.appendChild(style)

        const printData = document.getElementById(id).innerHTML


          this.PageSetup_Null();//把页眉页脚设置为空


        window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
        window.print() // 开始打印
    },
    PageSetup_Null(){
        var HKEY_Root,HKEY_Path,HKEY_Key;

        HKEY_Root="HKEY_CURRENT_USER";

        HKEY_Path="\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";

        try{
            var Wsh=new ActiveXObject("WScript.Shell");

            HKEY_Key="header";

            Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"");

            HKEY_Key="footer";

            Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"");

        }catch(e){}

        }

  }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'aleo-bold';
    src: url('../../assets/font/aleo-bold.ttf');
}
@font-face {
    font-family: 'Aleo-Bold-2';
    src: url('../../assets/font/Aleo-Bold-2.otf');
}
@font-face {
    font-family: 'Aleo-Italic-4';
    src: url('../../assets/font/Aleo-Italic-4.otf');
}
@font-face {
    font-family: 'Aleo-Light-5';
    src: url('../../assets/font/Aleo-Light-5.otf');
}
@font-face {
    font-family: 'Aleo-Regular-7';
    src: url('../../assets/font/Aleo-Regular-7.otf');
}
.certContainer {
  position: relative;
  color: rgb(42, 70, 154);
  .model {
    width: 297mm;
    height: 200.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 430px;
    left: 116px;
    font-weight: 500;
  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 525px;
    left: 250px;
    font-weight: 500;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }
   
  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 300px;
    left: 116px;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    .name {
      margin-right: 40px;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 260px;
    left: 116px;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    background-color:  red;
  }
}
.certContainer1{
    position: relative;
    color: white;
    .model{
        width: 365.28mm;
        height: 205.47mm;
        z-index: -10;   
    }
    .event{
        position: absolute;
        font-size: 70px;
        top: 100px;
        left: 86px;
        font-weight: 300;
        width: 600px;
    }
    .award{
        position: absolute;
        font-size: 90px;
        top: 300px;
        left: 86px;
        font-weight: 600;
    }
    .student{
        position: absolute;
        width: 600px;
        top: 460px;
        left: 86px;
        font-size: 40px;
        font-style: italic;
        font-weight: 300;
        .name{
            margin-right: 40px;
            word-wrap: none;
        }
    }
    .school{
        position: absolute;
        top: 570px;
        left: 86px;
        font-size: 30px;
        font-weight: 300;
    }
}
.certContainer5{
  position: relative;
  color: black;
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 390px;
    // left: 116px;
    text-align: center;
    font-weight: 500;
    width: 297mm;

  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_rank {
    position: absolute;
    font-size: 28px;
    top: 470px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  
  .award_team {
    position: absolute;
    font-size: 28px;
    top: 450px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;

  }
  .award_team_out{
    position: absolute;
    font-size: 28px;
    top: 410px;
    // left: 250px;
    font-weight: 500;
    width: 297mm;
    text-align: center;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }
   
  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name {
        text-align: center;
        margin-right: 0px;
        width: 297mm;
    }
  }
  .student_rank {
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_rank {
        text-align: center;
        margin-right: 0px;
        width: 297mm;
    }
  }
  .student_team {
    position: absolute;
    top: 300px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
        text-align: center;
        margin-right: 0px;
        width: 297mm;
    }
  }
  .student_team_out{
    position: absolute;
    top: 280px;
    // left: 116px;
    // right: 116px;
    width: 297mm;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    text-align: center;
    // background-color: rebeccapurple;
    .name_team {
        text-align: center;
        margin-right: 0px;
        width: 297mm;
    }
  }
  .school_team_out{
    position: absolute;
    top: 350px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_out_team{
    width: 297mm;
    position: absolute;
    top: 466px;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .school_rank {
    position: absolute;
    top: 340px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .info_rank{
    position: absolute;
    top: 390px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.5;
  }
  .school_team {
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 297mm;
    text-align: center;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  red;
  }
}
.transcriptContainer{
    position: relative;
    margin-bottom:-4px;
    .model_p{
        width: 210mm;
        height: 295mm;
        z-index: 0;
    }
    .name{
        position: absolute;
        top: 326px;
        left: 38px;
        font-size: 30px;
    
    }
    .info{
        position: absolute;
        top:384px;
        left: 38px;
        font-size: 15px;
        .tag{
            font-family: 'Aleo-Bold-2';
        }
        .content{
            font-family: 'Aleo-Regular-7';
        }
    }
    .score{
        position: absolute;
        top: 500px;
        width:320px;
        
        left:55px;
        font-size: 12px;
        font-family: 'Aleo-Regular-7';
        span{
            font-family: 'Aleo-Bold-2';
            font-size: 14px;
        }
        .title{
            font-family: 'aleo-bold';
        }
    }
    .score1{
        position: absolute;
        top: 500px;
        width:320px;
        left:434px;
        font-size: 15px;
        font-family: 'Aleo-Regular-7';
        span{
            font-family: 'Aleo-Bold-2';
            font-size: 17px;
        }
        .title{
            font-family: 'aleo-bold';
        }
    }
}

</style>
